import React from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

export default function Navbar() {
    const navigate = useNavigate();
    const handleLoginBtn = () => { navigate("login"); }

    return (
        <header className="navbar-parent m-auto">
            <div className="container-fluid px-0">
                <nav className="navbar navbar-expand-sm navbar-dark py-0 px-0">
                    <a className="navbar-brand d-flex" href="/home">
                        <img src="/images/studegy-logo.png" id="logo" className="navbar-logo" alt="Studegy header logo"></img>
                        <span className="navbar-brand-text mt-auto mb-auto">Studegy</span></a>
                    <span className="v-line"></span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav d-flex w-100">
                            <li className="nav-item active">
                                <a className="nav-link" href="/home">Home</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/about-us">About Us</a>
                            </li>
                            <li className="nav-item active btn-hover-close" id="login-btn" onClick={handleLoginBtn}>
                                <a className="nav-link" id="login-btn-text" href="/login">Login</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
}