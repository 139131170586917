import React from "react";
import {Route, BrowserRouter, Routes} from "react-router-dom";

import './App.css';
import './utils/Animations.css';
import Home from './pages/Home/Home.js';
import Navbar from './components/Navbar/Navbar.js';
import AboutUs from './pages/About Us/AboutUs.js';
import Login from "./pages/Login/Login";

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
      <Navbar/>
      <div id="body-content">
        <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/home" element={<Home/>} />
            <Route exact path="/about-us" element={<AboutUs/>} />
            <Route exact path="/login" element={<Login/>} />
        </Routes>
      </div>      
    </div>
  );
}

export default App;
