import React from 'react';
import "./Home.css";

export default function Home() {
    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="text-center p-3 spiral-in mt-auto mb-auto">
                <img src="/images/studegy-logo-light.png" alt="Studegy logo" className="mb-5 home-logo infinite-float"/>
                <h6 className="home-title text-center hover-grow-twist">Studegy</h6>
                <p className="home-text">Stressed with school?
                    <br/>Don't be.<br/>
                    Studegy is <span className="fw-bolder fst-italic">coming</span>
                </p>
            </div>
        </div>
    )
}