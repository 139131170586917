import React from "react";

export default function Login(){
    return (
        <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="text-center p-3 fall-in-bounce mt-auto mb-auto">
            <img src="/images/studegy-logo-light.png" alt="Studegy logo" className="mb-5 home-logo infinite-float"/>
            <h1>Registration is <span className="fw-bold">coming.</span></h1>
        </div>
    </div>
    );
}