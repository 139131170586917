import React from "react";
import './AboutUs.css';

export default function AboutUs() {
    const sectionDelayIncrement = 0.2; //In Seconds

    return (
        <div>
            <div className="vh-100 d-flex justify-content-center align-items-center">
            <div className="text-center p-3 mt-auto mb-auto">
                <section className="section-offset-left slide-left-right mt-5 mb-5" style={{animationDelay: `${sectionDelayIncrement}s`}}>
                        <h2 className="mb-4">What is Studegy?
                            &nbsp;<img className="logo-small" src="/images/studegy-logo-light.png" alt="Studegy Logo"/>
                        </h2>
                        <p className="about-us-text mt-4">Studegy is a personalized school management system that allows you to streamline your 
                        school work to help you succeed.</p>
                </section>
                <hr class="border border-secondary border-2 opacity-75"></hr>
                <section className="section-offset-right slide-right-left mt-5 mb-5" style={{animationDelay: `${sectionDelayIncrement * 2}s`}}>
                    <h2 className="mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="white" class="bi bi-pencil-fill" viewBox="0 0 16 16">
                        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                    </svg>&nbsp;Classes and Assignments</h2>
                    <p className="about-us-text mt-4">With Studegy, you can keep track of all your classes and assignments!</p>
                </section>
                <hr class="border border-secondary border-2 opacity-75"></hr>
                <section className="section-offset-left slide-left-right mt-5 mb-5" style={{animationDelay: `${sectionDelayIncrement * 3}s`}}>
                    <h2 className="mb-4">No More Notebook!&nbsp;
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="white" className="bi bi-journal" viewBox="0 0 16 16">
                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
                    </svg></h2>
                    <p className="about-us-text mt-4">No need for a notebook anymore, you can take notes directly in Studegy!</p>
                </section>
            </div>
        </div>
        </div>
    );
}